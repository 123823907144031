<script>
import { EPMC_FUNDERS as epmcFunders } from '@/config'
import { replaceUrlQuery } from 'epmc-patterns/helpers'
import { Tabs } from 'epmc-patterns/components/v2'
import { Dropdown } from 'epmc-patterns/components/v3'
import ImpactTab from '@/templates/funder_dashboard/ImpactTab'
import OpenAccessTab from '@/templates/funder_dashboard/OpenAccessTab'

// still a little buggy***
const dates = () => ['', '']

const tabs = [
  {
    name: 'Open Access',
    component: 'open-access-tab',
    dates: dates(),
  },
  {
    name: 'Impact',
    component: 'impact-tab',
    dates: dates(),
  },
]

export default {
  metaInfo() {
    return {
      title: 'Funder dashboard - Europe PMC',
    }
  },
  components: { Dropdown, ImpactTab, OpenAccessTab, Tabs },
  props: {
    funderName: {
      type: String,
      default: epmcFunders[0].value[0],
    },
    datesStr: {
      type: String,
      default: dates().toString(),
    },
    tabName: {
      type: String,
      default: tabs[0].name,
    },
  },
  data() {
    return {
      funderOptions: [],
      disabled: false,
      tabs,
      sync: false,
    }
  },
  computed: {
    selectedFunder() {
      return epmcFunders[
        this.funderOptions.findIndex((option) => option.selected)
      ]
    },
    currentTab() {
      return tabs.findIndex((tab) => tab.name === this.tabName)
    },
    dates() {
      return this.datesStr.split(',')
    },
  },
  created() {
    this.funderOptions = epmcFunders.map((funder, index) => {
      const con = this.funderName
        ? funder.text === this.funderName
        : index === 0
      const selected = con ? true : false
      return { text: funder.text, value: index, selected }
    })
    this.$router.push({
      path:
        'funder-dashboard' +
        replaceUrlQuery({ funderName: this.funderName, tabName: this.tabName }),
    })
  },
  methods: {
    selectFunder() {
      this.$router.push({
        path:
          'funder-dashboard' +
          replaceUrlQuery({ funderName: this.selectedFunder.value[0] }),
      })
    },
    selectTab(index) {
      const query = { tabName: tabs[index].name }
      if (this.sync) {
        const datesToSync = this.tabs[index === 0 ? 1 : 0].dates
        this.tabs[index].dates = [
          datesToSync[0] && '01 Jan ' + new Date(datesToSync[0]).getFullYear(),
          datesToSync[1] && '31 Dec ' + new Date(datesToSync[1]).getFullYear(),
        ]
        query.datesStr = this.tabs[index].dates.toString()
        this.sync = false
      } else if (index === 1) {
        // performance can be improved
        const { dates } = this
        query.datesStr = [
          dates[0] && '01 Jan ' + new Date(dates[0]).getFullYear(),
          dates[1] && '31 Dec ' + new Date(dates[1]).getFullYear(),
        ]
      }
      this.$router.push({
        path: 'funder-dashboard' + replaceUrlQuery(query),
      })
    },
    selectDisabled(disabled) {
      this.disabled = disabled
    },
    updateDates(params) {
      const { dates, tabIndex } = params
      const currentDates = this.tabs[tabIndex].dates
      if (currentDates[0] !== dates[0] || currentDates[1] !== dates[1]) {
        this.tabs[tabIndex].dates = dates
        this.$router.push({
          path:
            'funder-dashboard' +
            replaceUrlQuery({
              tabName: tabs[tabIndex].name,
              datesStr: dates.toString(),
            }),
        })
        if (
          new Date(currentDates[0]).getFullYear() !==
            new Date(dates[0]).getFullYear() ||
          new Date(currentDates[1]).getFullYear() !==
            new Date(dates[1]).getFullYear()
        ) {
          this.sync = true
        }
      }
    },
  },
}
</script>
<template>
  <div id="funder-dashboard-page">
    <div class="grid-row">
      <div class="col-16">
        <div id="funder-dashboard-heading">
          <h1>{{ selectedFunder.text }}</h1>
          <div>
            <label for="funder-select-dropdown" class="semi-bold">
              Select funder:
            </label>
            <dropdown
              id="funder-select-dropdown"
              :class="['select-funder', { disabled }]"
              :options="funderOptions"
              @onOptionSelected="selectFunder"
            />
          </div>
        </div>

        <tabs
          :tabs="tabs"
          :current-tab="currentTab"
          :table-layout="false"
          @select="selectTab"
        >
          <h2 slot="title" slot-scope="{ tab }">
            {{ tab.name }}
          </h2>
          <component
            :is="tab.component"
            slot="content"
            slot-scope="{ tab }"
            :funder="selectedFunder"
            :dates="dates"
            @selectDisabled="selectDisabled"
            @updateDates="updateDates"
          />
        </tabs>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#funder-dashboard-page {
  margin-top: $base-unit * 7;
  margin-bottom: $base-unit * 13;
  #funder-dashboard-heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    h1 {
      margin-bottom: $base-unit * 3;
    }
    .dropdown-v3 {
      margin-bottom: $base-unit * 10;
      width: $base-unit * 100;
    }
  }
  .fa-question-circle {
    line-height: $base-unit * 8;
    vertical-align: top;
  }
  .content-section {
    margin-top: $base-unit * 10;
  }
  .select-dates-container {
    text-align: right;
    .vdp-datepicker-label {
      @media (max-width: $breakpoint-small) {
        display: block;
        margin-bottom: $base-unit * 2;
        text-align: right;
      }
    }
  }
  .chart-container {
    position: relative;
    height: $base-unit * 165;
    @media screen and (max-width: $breakpoint-large) {
      height: $base-unit * 135;
    }
    @media screen and (max-width: $breakpoint-small) {
      height: $base-unit * 100;
    }
    @media screen and (max-width: $breakpoint-extra-small - 200) {
      height: $base-unit * 75;
    }
    &.half {
      display: inline-block;
      width: 50%;
      @media screen and (max-width: $breakpoint-extra-small) {
        width: 100%;
        height: $base-unit * 65;
        & + .half {
          margin-top: $base-unit * 6;
        }
      }
      height: $base-unit * 75;
    }
  }
}
</style>
