<script>
import { fetchYearlyCountsByGrantData } from '@/api'
import { formatDate, getDate } from 'epmc-patterns/helpers'
import { getChartData, getYearsFromDates } from '@/helpers/funder-dashboard'
import {
  Chart,
  ContentSection,
  DatePicker,
  FeaturedNumber,
  Loading,
  Notification,
  Tooltip,
} from 'epmc-patterns/components/v2'

export default {
  components: {
    Chart,
    ContentSection,
    DatePicker,
    FeaturedNumber,
    Loading,
    Notification,
    Tooltip,
  },
  props: {
    funder: {
      type: Object,
      default: () => {},
    },
    dates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allCount: 0,
      allCountRatio: '',
      abstractOnlyCount: 0,
      abstractOnlyCountRatio: '',
      fulltextCount: 0,
      fulltextCountRatio: '',
      openAccessCount: 0,
      openAccessCountRatio: '',

      oaChart: {
        title: 'Open Access compliance by year',
        charts: [
          {
            type: 'bar',
            labels: [],
            datasets: [],
            update: false,
            options: {
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString:
                        'Publication date of articles supported by funder',
                      fontSize: 16,
                    },
                  },
                ],
              },
            },
          },
        ],
      },
      sdChart: {
        title: 'Articles with linked data',
        charts: [
          {
            type: 'bar',
            labels: [],
            datasets: [],
            update: false,
            options: {
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString:
                        'Publication date of articles supported by funder',
                      fontSize: 16,
                    },
                  },
                ],
              },
            },
          },
        ],
      },
    }
  },
  computed: {
    highlighted() {
      return {
        to: new Date(this.dates[1]),
        from: new Date(this.dates[0]),
      }
    },
    disabled() {
      const { oaChart, sdChart } = this
      const charts = [oaChart, sdChart]
      const disabled = charts.some((chart) => !chart.charts[0].update)
      this.$emit('selectDisabled', disabled)
      return disabled
    },
  },
  watch: {
    funder: function () {
      this.loadData()
    },
    dates: {
      deep: true,
      handler() {
        this.loadData()
      },
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    onDateSelected(date, index) {
      const dates = this.dates.slice(0)
      dates[index] = formatDate(date)
      this.$emit('updateDates', { dates, tabIndex: 0 })
    },
    last(dates) {
      this.$emit('updateDates', { dates, tabIndex: 0 })
    },
    loadData() {
      const { dates, funder } = this
      const oaChart = this.oaChart.charts[0]
      const sdChart = this.sdChart.charts[0]
      new Array(
        'allCount',
        'abstractOnlyCount',
        'fulltextCount',
        'openAccessCount'
      ).forEach((count) => {
        this[count] = 0
      })
      new Array(
        'allCountRatio',
        'abstractOnlyCountRatio',
        'fulltextCountRatio',
        'openAccessCountRatio'
      ).forEach((ratio) => {
        this[ratio] = ''
      })
      oaChart.labels = []
      oaChart.datasets = []
      oaChart.update = false
      sdChart.labels = []
      sdChart.datasets = []
      sdChart.update = false

      const sysDates = [
        getDate(dates[0], 'system'),
        getDate(dates[1], 'system'),
      ]
      const grantAgencies = funder.value

      const getCount = (yearlyCounts) => {
        return yearlyCounts.length
          ? yearlyCounts.map((yc) => yc.count).reduce((acc, val) => acc + val)
          : 0
      }

      const getBelowText = (x) => {
        return '(' + Number.parseFloat(x * 100).toFixed(1) + '%)'
      }

      Promise.all(
        ['', 'abstractOnly', 'hasFulltext', 'isOpenAccess'].map((type) =>
          fetchYearlyCountsByGrantData({
            dates: sysDates,
            grantAgencies,
            type,
          })
        )
      ).then((response) => {
        // whether use api instead
        this.allCount = getCount(response[0])
        this.abstractOnlyCount = getCount(response[1])
        this.fulltextCount = getCount(response[2])
        this.openAccessCount = getCount(response[3])
        if (this.allCount > 0) {
          this.allCountRatio = '(100%)'
          this.abstractOnlyCountRatio = getBelowText(
            this.abstractOnlyCount / this.allCount
          )
          this.fulltextCountRatio = getBelowText(
            this.fulltextCount / this.allCount
          )
          this.openAccessCountRatio = getBelowText(
            this.openAccessCount / this.allCount
          )
        }

        const yearSet = new Set(
          getYearsFromDates(response[1], dates[0], dates[1])
        )
        const yearSet1 = new Set(
          getYearsFromDates(response[2], dates[0], dates[1])
        )
        const yearSet2 = new Set(
          getYearsFromDates(response[3], dates[0], dates[1])
        )
        oaChart.labels = Array.from(
          new Set([...yearSet, ...yearSet1, ...yearSet2])
        )
        oaChart.labels.sort()

        oaChart.datasets.push(
          {
            label: 'Abstract only',
            data: getChartData(response[1], oaChart.labels),
            backgroundColor: '#A3A3A3',
            borderColor: '#A3A3A3',
            hoverBackgroundColor: '#BCBCBC',
          },
          {
            label: 'Full-text',
            data: getChartData(response[2], oaChart.labels),
            backgroundColor: '#3C7EAD',
            borderColor: '#3C7EAD',
            hoverBackgroundColor: '#5797C5',
          },
          {
            label: 'Open Access',
            data: getChartData(response[3], oaChart.labels),
          }
        )

        oaChart.update = true
      })

      Promise.all(
        [true, false].map((hasData) =>
          fetchYearlyCountsByGrantData({
            dates: sysDates,
            grantAgencies,
            hasData,
          })
        )
      ).then((response) => {
        const yearSet = new Set(
          getYearsFromDates(response[0], dates[0], dates[1])
        )
        const yearSet1 = new Set(
          getYearsFromDates(response[1], dates[0], dates[1])
        )
        sdChart.labels = Array.from(new Set([...yearSet, ...yearSet1]))
        sdChart.labels.sort()

        sdChart.datasets.push(
          {
            label: 'Articles with linked data',
            data: getChartData(response[0], sdChart.labels),
          },
          {
            label: 'Articles with no linked data found',
            data: getChartData(response[1], sdChart.labels),
          }
        )

        sdChart.update = true
      })
    },
  },
}
</script>
<template>
  <div>
    <div>
      <div class="select-dates-container">
        <div class="select-dates">
          <date-picker
            id="openDateStart"
            class="date-picker-from"
            :date="dates[0]"
            :disabled="disabled"
            :disabled-dates="{ from: new Date(dates[1]) }"
            :highlighted="highlighted"
            label="From"
            minimum-view="month"
            maximum-view="year"
            @lastYear="last"
            @lastMonth="last"
            @selected="onDateSelected($event, 0)"
          />
          <date-picker
            id="openDateEnd"
            :date-start="false"
            :date="dates[1]"
            :disabled="disabled"
            :disabled-dates="{ to: new Date(dates[0]) }"
            :highlighted="highlighted"
            label="To"
            minimum-view="month"
            maximum-view="year"
            @lastYear="last"
            @lastMonth="last"
            @selected="onDateSelected($event, 1)"
          />
        </div>
      </div>
    </div>
    <div class="has-sections">
      <div class="featured-numbers">
        <featured-number
          :number="allCount"
          above-text="All articles"
          :below-text="allCountRatio"
          size="large"
        />
        <featured-number
          :number="abstractOnlyCount"
          above-text="Abstract only"
          :below-text="abstractOnlyCountRatio"
          size="large"
        />
        <featured-number
          :number="fulltextCount"
          above-text="Full text"
          :below-text="fulltextCountRatio"
          size="large"
        />
        <featured-number
          :number="openAccessCount"
          above-text="Open Access"
          :below-text="openAccessCountRatio"
          size="large"
        />
      </div>

      <content-section
        v-for="chartData in [oaChart, sdChart]"
        :key="chartData.title"
        :section="{
          heading: 'h2',
          title: chartData.title,
          id: chartData.title.split(' ').join('-'),
        }"
      >
        <tooltip
          v-if="chartData.title === 'Articles with linked data'"
          slot="leftSlot"
        >
          <i slot="trigger" class="far fa-question-circle" />
          Europe PMC identifies supporting and related data linked to an
          article. Supporting data includes supplemental files or text-mined
          data accessions found in the full text of the article. Related data
          includes curated or other data, which cite the article.
        </tooltip>
        <template v-for="(chart, index) in chartData.charts">
          <loading v-if="!chart.update" :key="index + 'loading'" />
          <notification
            v-else-if="!chart.labels.length"
            :key="index + 'notification'"
          >
            No chart available because there is no data for the selected time
            period.
          </notification>
          <div
            :key="index"
            :class="[
              'chart-container',
              { half: chartData.charts.length === 2 },
            ]"
          >
            <chart
              v-show="chart.update && chart.labels.length"
              :type="chart.type"
              :labels="chart.labels"
              :datasets="chart.datasets"
              :options="chart.options"
              :update="chart.update"
              :aria-label="chartData.title"
              role="img"
            />
          </div>
        </template>
      </content-section>
    </div>
  </div>
</template>
<style lang="scss">
.featured-numbers {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  & > * {
    flex-basis: 20%;
    margin-top: $base-unit * 9;
    @media screen and (max-width: $breakpoint-extra-small - 60) {
      flex-basis: 40%;
      flex-shrink: 0;
    }
    @media screen and (max-width: $breakpoint-smallest - 20) {
      flex-basis: 100%;
    }
  }
}
</style>
