<script>
import {
  fetchYearlyCountsByGrantData,
  fetchCitationsByGrantData,
  fetchYearlyCitationsByGrantData,
} from '@/api'
import { formatDate, getDate } from 'epmc-patterns/helpers'
import { getChartData, getYearsFromDates } from '@/helpers/funder-dashboard'
import {
  Chart,
  ContentSection,
  DatePicker,
  Loading,
  Notification,
  Tooltip,
} from 'epmc-patterns/components/v2'

export default {
  components: {
    Chart,
    ContentSection,
    DatePicker,
    Loading,
    Notification,
    Tooltip,
  },
  props: {
    funder: {
      type: Object,
      default: () => {},
    },
    dates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      annualChart: {
        title: 'Annual citations',
        charts: [
          {
            type: 'bar',
            labels: [],
            datasets: [],
            update: false,
            options: {
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Publication date of citing articles',
                      fontSize: 16,
                    },
                  },
                ],
              },
            },
          },
        ],
      },
      pieCharts: {
        title: 'Articles with an ORCID',
        charts: [
          {
            type: 'pie',
            labels: ['Articles with an ORCID', 'Articles without an ORCID'],
            datasets: [],
            options: {
              legend: {
                position: 'bottom',
              },
              title: {
                display: true,
                fontSize: 18,
                text: 'All articles in Europe PMC',
              },
              maintainAspectRatio: false,
            },
            update: false,
          },
          {
            type: 'pie',
            labels: ['Articles with an ORCID', 'Articles without an ORCID'],
            datasets: [],
            options: {
              legend: {
                position: 'bottom',
              },
              title: {
                display: true,
                fontSize: 18,
                text: '',
              },
              maintainAspectRatio: false,
            },
            update: false,
          },
        ],
      },
      preprintChart: {
        title: 'Articles based on a preprint',
        charts: [
          {
            type: 'bar',
            labels: [],
            datasets: [],
            update: false,
            options: {
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString:
                        'Publication date of articles supported by funder',
                      fontSize: 16,
                    },
                  },
                ],
              },
            },
          },
        ],
      },
    }
  },
  computed: {
    disabled() {
      const { annualChart, pieCharts, preprintChart } = this
      let charts = []
      const chartTypes = [annualChart, pieCharts, preprintChart]
      chartTypes.forEach((type) => {
        charts = charts.concat(type.charts)
      })
      const disabled = charts.some((chart) => !chart.update)
      this.$emit('selectDisabled', disabled)
      return disabled
    },
  },
  watch: {
    funder: function () {
      this.loadData()
    },
    dates: {
      deep: true,
      handler() {
        this.loadData()
        this.loadDataForPubMedChart()
      },
    },
  },
  created() {
    this.loadData()
    this.loadDataForPubMedChart()
  },
  methods: {
    onDateSelected(date, index) {
      const dates = this.dates.slice(0)
      dates[index] = formatDate(date)
      this.$emit('updateDates', { dates, tabIndex: 1 })
    },
    last(dates) {
      this.$emit('updateDates', { dates, tabIndex: 1 })
    },
    loadData() {
      const { dates, funder } = this
      const annualChart = this.annualChart.charts[0]
      const FWFChart = this.pieCharts.charts[1]
      const preprintChart = this.preprintChart.charts[0]

      annualChart.labels = []
      annualChart.datasets = []
      annualChart.update = false
      FWFChart.datasets = []
      FWFChart.update = false
      preprintChart.labels = []
      preprintChart.datasets = []
      preprintChart.update = false

      const sysDates = [
        getDate(dates[0], 'system'),
        getDate(dates[1], 'system'),
      ]
      const grantAgencies = funder.value

      fetchYearlyCitationsByGrantData(grantAgencies[0]).then((response) => {
        annualChart.labels = getYearsFromDates(response, dates[0], dates[1])
        annualChart.datasets.push({
          label: 'Citations of articles supported by funder',
          data: getChartData(response, annualChart.labels),
        })
        annualChart.update = true
      })

      Promise.all([
        fetchCitationsByGrantData({
          dates: sysDates,
          grantAgencies,
          withOrcid: true,
        }),
        fetchCitationsByGrantData({ dates: sysDates, grantAgencies }),
      ]).then((response) => {
        FWFChart.datasets.push({
          data: [
            response[0].hitCount,
            response[1].hitCount - response[0].hitCount,
          ],
        })
        const text = this.funder.text + ' articles in Europe PMC'
        const textArr = text.split(' ')
        const indexOfIn = textArr.indexOf('in')
        const maxNumberOfWords = 7
        const index =
          indexOfIn < maxNumberOfWords ? indexOfIn : maxNumberOfWords
        FWFChart.options.title.text =
          textArr.length < maxNumberOfWords
            ? [text]
            : [
                textArr.slice(0, index).join(' '),
                textArr.slice(index, textArr.length).join(' '),
              ]
        FWFChart.update = true
      })

      fetchYearlyCountsByGrantData({
        dates: sysDates,
        grantAgencies,
        hasPreprint: true,
      }).then((response) => {
        preprintChart.labels = getYearsFromDates(response, dates[0], dates[1])
        preprintChart.datasets.push({
          label: 'Articles based on a preprint',
          data: getChartData(response, preprintChart.labels),
        })
        preprintChart.update = true
      })
    },
    loadDataForPubMedChart() {
      const { dates } = this
      const sysDates = [
        getDate(dates[0], 'system'),
        getDate(dates[1], 'system'),
      ]

      const PubMedChart = this.pieCharts.charts[0]
      PubMedChart.datasets = []
      PubMedChart.update = false

      Promise.all([
        fetchCitationsByGrantData({ dates: sysDates, withOrcid: true }),
        fetchCitationsByGrantData({ dates: sysDates }),
      ]).then((response) => {
        PubMedChart.datasets.push({
          data: [
            response[0].hitCount,
            response[1].hitCount - response[0].hitCount,
          ],
        })
        PubMedChart.update = true
      })
    },
  },
}
</script>
<template>
  <div>
    <div class="select-dates-container">
      <div class="select-dates">
        <date-picker
          id="impactDateStart"
          class="date-picker-from"
          :date="dates[0]"
          :disabled="disabled"
          :disabled-dates="{ from: new Date(dates[1]) }"
          label="From"
          minimum-view="year"
          maximum-view="year"
          placeholder="Select year"
          @lastYear="last"
          @selected="onDateSelected($event, 0)"
        />
        <date-picker
          id="impactDateEnd"
          :date-start="false"
          :date="dates[1]"
          :disabled="disabled"
          :disabled-dates="{ to: new Date(dates[0]) }"
          label="To"
          minimum-view="year"
          maximum-view="year"
          placeholder="Select year"
          @lastYear="last"
          @selected="onDateSelected($event, 1)"
        />
      </div>
    </div>
    <div class="has-sections">
      <content-section
        v-for="chartData in [annualChart, pieCharts, preprintChart]"
        :key="chartData.title"
        :section="{
          heading: 'h2',
          title: chartData.title,
          id: chartData.title.split(' ').join('-'),
        }"
      >
        <tooltip
          v-if="chartData.title === 'Articles based on a preprint'"
          slot="leftSlot"
        >
          <i slot="trigger" class="far fa-question-circle" />
          Preprints are articles which have not been peer-reviewed or published
          in a journal. Preprints enable researchers to share their scientific
          findings freely and quickly with the scholarly community, but may
          later undergo a peer review process and be published in a journal, or
          on an open research platform. Europe PMC provides links between the
          preprint and published versions of the article.
        </tooltip>
        <tooltip
          v-else-if="chartData.title === 'Annual citations'"
          slot="leftSlot"
        >
          <i slot="trigger" class="far fa-question-circle" />
          The total number of citations made to research articles supported by
          this funder, by articles published in a given year.
        </tooltip>
        <template v-for="(chart, index) in chartData.charts">
          <loading v-if="!chart.update" :key="index + 'loading'" />
          <notification
            v-else-if="!chart.labels.length"
            :key="index + 'notification'"
          >
            No chart available because there is no data for the selected time
            period.
          </notification>
          <div
            :key="index"
            :class="[
              'chart-container',
              { half: chartData.charts.length === 2 },
            ]"
          >
            <chart
              v-show="chart.update && chart.labels.length"
              :type="chart.type"
              :labels="chart.labels"
              :datasets="chart.datasets"
              :options="chart.options"
              :update="chart.update"
              :aria-label="chartData.title"
              role="img"
            />
          </div>
        </template>
      </content-section>
    </div>
  </div>
</template>
