export function getChartData(yearlyCounts, years) {
  return years.map((year) => {
    const match = yearlyCounts.find(
      (yc) => yc.year === year || (yc.id && yc.id.pubyear === year)
    )
    return match ? match.count || match.id.citingArticlesCount : 0
  })
}

export function getYearsFromDates(yearlyCounts, startDate, endDate) {
  let years = []
  if (yearlyCounts.length) {
    const startYear = startDate
      ? new Date(startDate).getFullYear()
      : Number(
          yearlyCounts[yearlyCounts.length - 1].year ||
            yearlyCounts[0].id.pubyear
        )
    const endYear = endDate
      ? new Date(endDate).getFullYear()
      : Number(
          yearlyCounts[0].year ||
            yearlyCounts[yearlyCounts.length - 1].id.pubyear
        )
    if (endYear >= startYear) {
      years = Array.from(Array(endYear - startYear + 1).keys()).map((inc) =>
        (inc + startYear).toString(10)
      )
    }
  }
  return years
}
