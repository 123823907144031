var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"select-dates-container"},[_c('div',{staticClass:"select-dates"},[_c('date-picker',{staticClass:"date-picker-from",attrs:{"id":"openDateStart","date":_vm.dates[0],"disabled":_vm.disabled,"disabled-dates":{ from: new Date(_vm.dates[1]) },"highlighted":_vm.highlighted,"label":"From","minimum-view":"month","maximum-view":"year"},on:{"lastYear":_vm.last,"lastMonth":_vm.last,"selected":function($event){return _vm.onDateSelected($event, 0)}}}),_c('date-picker',{attrs:{"id":"openDateEnd","date-start":false,"date":_vm.dates[1],"disabled":_vm.disabled,"disabled-dates":{ to: new Date(_vm.dates[0]) },"highlighted":_vm.highlighted,"label":"To","minimum-view":"month","maximum-view":"year"},on:{"lastYear":_vm.last,"lastMonth":_vm.last,"selected":function($event){return _vm.onDateSelected($event, 1)}}})],1)])]),_c('div',{staticClass:"has-sections"},[_c('div',{staticClass:"featured-numbers"},[_c('featured-number',{attrs:{"number":_vm.allCount,"above-text":"All articles","below-text":_vm.allCountRatio,"size":"large"}}),_c('featured-number',{attrs:{"number":_vm.abstractOnlyCount,"above-text":"Abstract only","below-text":_vm.abstractOnlyCountRatio,"size":"large"}}),_c('featured-number',{attrs:{"number":_vm.fulltextCount,"above-text":"Full text","below-text":_vm.fulltextCountRatio,"size":"large"}}),_c('featured-number',{attrs:{"number":_vm.openAccessCount,"above-text":"Open Access","below-text":_vm.openAccessCountRatio,"size":"large"}})],1),_vm._l(([_vm.oaChart, _vm.sdChart]),function(chartData){return _c('content-section',{key:chartData.title,attrs:{"section":{
        heading: 'h2',
        title: chartData.title,
        id: chartData.title.split(' ').join('-'),
      }}},[(chartData.title === 'Articles with linked data')?_c('tooltip',{attrs:{"slot":"leftSlot"},slot:"leftSlot"},[_c('i',{staticClass:"far fa-question-circle",attrs:{"slot":"trigger"},slot:"trigger"}),_vm._v("\n        Europe PMC identifies supporting and related data linked to an\n        article. Supporting data includes supplemental files or text-mined\n        data accessions found in the full text of the article. Related data\n        includes curated or other data, which cite the article.\n      ")]):_vm._e(),_vm._l((chartData.charts),function(chart,index){return [(!chart.update)?_c('loading',{key:index + 'loading'}):(!chart.labels.length)?_c('notification',{key:index + 'notification'},[_vm._v("\n          No chart available because there is no data for the selected time\n          period.\n        ")]):_vm._e(),_c('div',{key:index,class:[
            'chart-container',
            { half: chartData.charts.length === 2 },
          ]},[_c('chart',{directives:[{name:"show",rawName:"v-show",value:(chart.update && chart.labels.length),expression:"chart.update && chart.labels.length"}],attrs:{"type":chart.type,"labels":chart.labels,"datasets":chart.datasets,"options":chart.options,"update":chart.update,"aria-label":chartData.title,"role":"img"}})],1)]})],2)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }