var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"select-dates-container"},[_c('div',{staticClass:"select-dates"},[_c('date-picker',{staticClass:"date-picker-from",attrs:{"id":"impactDateStart","date":_vm.dates[0],"disabled":_vm.disabled,"disabled-dates":{ from: new Date(_vm.dates[1]) },"label":"From","minimum-view":"year","maximum-view":"year","placeholder":"Select year"},on:{"lastYear":_vm.last,"selected":function($event){return _vm.onDateSelected($event, 0)}}}),_c('date-picker',{attrs:{"id":"impactDateEnd","date-start":false,"date":_vm.dates[1],"disabled":_vm.disabled,"disabled-dates":{ to: new Date(_vm.dates[0]) },"label":"To","minimum-view":"year","maximum-view":"year","placeholder":"Select year"},on:{"lastYear":_vm.last,"selected":function($event){return _vm.onDateSelected($event, 1)}}})],1)]),_c('div',{staticClass:"has-sections"},_vm._l(([_vm.annualChart, _vm.pieCharts, _vm.preprintChart]),function(chartData){return _c('content-section',{key:chartData.title,attrs:{"section":{
        heading: 'h2',
        title: chartData.title,
        id: chartData.title.split(' ').join('-'),
      }}},[(chartData.title === 'Articles based on a preprint')?_c('tooltip',{attrs:{"slot":"leftSlot"},slot:"leftSlot"},[_c('i',{staticClass:"far fa-question-circle",attrs:{"slot":"trigger"},slot:"trigger"}),_vm._v("\n        Preprints are articles which have not been peer-reviewed or published\n        in a journal. Preprints enable researchers to share their scientific\n        findings freely and quickly with the scholarly community, but may\n        later undergo a peer review process and be published in a journal, or\n        on an open research platform. Europe PMC provides links between the\n        preprint and published versions of the article.\n      ")]):(chartData.title === 'Annual citations')?_c('tooltip',{attrs:{"slot":"leftSlot"},slot:"leftSlot"},[_c('i',{staticClass:"far fa-question-circle",attrs:{"slot":"trigger"},slot:"trigger"}),_vm._v("\n        The total number of citations made to research articles supported by\n        this funder, by articles published in a given year.\n      ")]):_vm._e(),_vm._l((chartData.charts),function(chart,index){return [(!chart.update)?_c('loading',{key:index + 'loading'}):(!chart.labels.length)?_c('notification',{key:index + 'notification'},[_vm._v("\n          No chart available because there is no data for the selected time\n          period.\n        ")]):_vm._e(),_c('div',{key:index,class:[
            'chart-container',
            { half: chartData.charts.length === 2 },
          ]},[_c('chart',{directives:[{name:"show",rawName:"v-show",value:(chart.update && chart.labels.length),expression:"chart.update && chart.labels.length"}],attrs:{"type":chart.type,"labels":chart.labels,"datasets":chart.datasets,"options":chart.options,"update":chart.update,"aria-label":chartData.title,"role":"img"}})],1)]})],2)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }